import React from 'react'
import { motion } from "framer-motion";

const icon = {
  hidden: {
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
}

const Hero = () => {
  return (
    <div id='hero' className='bg-waves-background bg-no-repeat bg-center bg-cover xl:bg-fixed h-screen flex flex-col items-center justify-center'>
      <div className='backdrop-blur-md w-64 h-64 lg:w-96 lg:h-96 rounded-3xl flex items-center justify-center shadow-md mb-4'>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00" width="500.00" height="500.00">
          <motion.path
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 0.5, ease: "easeInOut" },
              fill: { duration: 0.5, ease: [1, 0, 0.8, 1] }
            }}
            fill="#ffffff"
            d="
            M 164.17 225.94
            C 147.37 230.12 132.08 241.11 121.78 254.67
            Q 120.05 256.95 120.00 254.08
            Q 119.62 228.20 120.01 202.12
            Q 120.08 197.94 121.72 191.27
            Q 123.05 185.85 125.08 181.22
            Q 130.83 168.09 138.37 159.76
            Q 152.18 144.53 171.55 138.40
            Q 179.62 135.85 188.31 135.78
            C 196.10 135.72 203.35 136.23 210.80 138.55
            C 221.43 141.87 232.70 147.42 240.89 155.19
            A 0.59 0.59 0.0 0 1 240.84 156.09
            Q 230.16 164.30 221.56 176.53
            C 218.60 180.75 216.78 185.16 214.64 189.69
            A 0.52 0.51 -50.6 0 1 213.76 189.78
            Q 203.30 175.61 185.85 177.56
            Q 177.35 178.51 171.06 184.23
            Q 157.14 196.86 160.85 215.09
            C 161.57 218.59 163.12 221.92 164.52 225.24
            Q 164.75 225.79 164.17 225.94
            Z"
          />
          <motion.path
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 1, ease: "easeInOut" },
              fill: { duration: 1, ease: [1, 0, 0.8, 1] }
            }}
            fill="#ffffff" d="
            M 231.80 216.65
            Q 237.86 201.02 248.04 187.33
            A 0.69 0.69 0.0 0 1 249.10 187.27
            C 259.89 198.91 270.44 211.25 281.73 222.45
            C 293.74 234.37 311.52 241.04 327.05 231.02
            C 333.14 227.09 338.64 220.24 339.90 213.22
            Q 343.27 194.43 328.32 182.95
            C 315.66 173.22 297.79 176.15 289.04 189.29
            C 286.95 192.42 285.59 196.66 284.19 200.26
            A 0.24 0.24 0.0 0 1 283.75 200.28
            C 279.02 191.12 273.30 182.64 266.15 174.87
            Q 260.63 168.87 253.78 162.01
            Q 253.33 161.56 253.80 161.12
            Q 259.32 156.09 265.16 151.69
            C 277.32 142.54 292.69 136.08 307.85 135.88
            Q 313.24 135.80 317.97 136.13
            Q 330.07 136.95 342.74 143.89
            Q 366.72 157.03 376.92 183.02
            C 383.50 199.80 382.52 218.60 375.20 234.87
            Q 370.31 245.75 360.52 256.38
            C 357.77 259.36 354.19 261.89 350.97 264.25
            C 339.29 272.84 324.64 277.59 310.38 277.25
            Q 292.85 276.82 277.19 267.61
            C 271.60 264.32 265.71 260.28 261.22 255.60
            Q 249.55 243.45 236.75 231.28
            Q 233.42 228.11 229.70 225.35
            A 1.25 1.24 27.0 0 1 229.25 223.98
            Q 230.43 220.18 231.80 216.65
            Z"
          />
          <motion.path
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 1.5, ease: "easeInOut" },
              fill: { duration: 1.5, ease: [1, 0, 0.8, 1] }
            }}
            fill="#ffffff" d="
            M 180.38 370.12
            Q 174.05 369.11 165.70 366.03
            Q 158.34 363.31 152.28 359.02
            Q 131.38 344.24 123.64 320.33
            Q 117.61 301.67 124.19 282.91
            Q 134.35 253.94 162.02 241.32
            Q 166.91 239.09 177.21 237.20
            Q 190.20 234.81 201.54 237.40
            C 206.74 238.59 212.26 239.81 216.96 242.18
            Q 228.21 247.87 236.99 256.94
            Q 248.85 269.19 260.24 280.66
            Q 265.32 285.78 271.33 289.86
            A 1.33 1.32 -61.6 0 1 271.81 291.48
            Q 266.57 303.83 258.38 316.80
            Q 255.24 321.78 251.51 325.00
            Q 251.11 325.34 250.72 325.00
            Q 245.81 320.75 241.91 316.27
            C 234.30 307.51 225.79 299.85 218.12 291.13
            C 213.21 285.55 207.75 281.91 200.43 279.45
            Q 188.48 275.43 177.82 279.45
            Q 169.57 282.56 165.34 288.58
            C 157.28 300.06 160.99 315.98 172.33 323.86
            C 177.00 327.12 180.79 329.17 186.78 329.71
            C 199.93 330.91 211.69 324.02 216.66 311.55
            Q 217.31 309.92 217.92 311.56
            C 218.51 313.11 218.80 314.76 219.62 316.23
            Q 230.19 335.11 246.18 347.33
            A 0.72 0.71 -44.3 0 1 246.16 348.48
            C 242.88 350.88 240.03 353.60 236.82 355.92
            Q 227.30 362.81 215.07 366.79
            Q 196.76 372.75 180.38 370.12
            Z"
          />
          <motion.path
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] }
            }}
            fill="#ffffff" d="
            M 376.45 324.88
            Q 365.82 350.47 342.81 362.79
            Q 337.08 365.86 327.15 368.64
            C 307.03 374.27 284.04 368.69 266.22 357.80
            Q 263.09 355.89 260.42 353.61
            A 1.26 1.26 0.0 0 1 260.37 351.74
            Q 265.49 346.88 271.10 341.90
            Q 272.74 340.44 277.11 334.47
            C 281.02 329.13 283.98 323.11 286.82 317.21
            Q 287.17 316.48 287.66 317.12
            C 288.60 318.35 289.28 319.68 290.37 320.78
            Q 300.06 330.60 313.02 329.90
            C 329.00 329.04 340.98 315.88 340.29 299.85
            Q 340.02 293.63 337.71 287.63
            A 1.02 1.02 0.0 0 1 338.38 286.29
            Q 347.61 283.61 355.36 279.35
            C 364.81 274.16 373.90 264.89 380.78 256.25
            Q 381.80 254.97 381.80 256.61
            Q 381.76 276.49 381.68 296.54
            Q 381.62 312.44 376.45 324.88
            Z"
          />
        </svg>
      </div>
      <div className='flex flex-col'>
        <div className='font-bold text-5xl lg:text-9xl text-white shadow-md'>Manuel Rios</div>
        <div className='font-medium text-3xl lg:text-5xl text-white shadow-md'>Full Stack Developer</div>
      </div>
    </div>
  )
}

export default Hero
